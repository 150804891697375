import React from "react";

import { Button } from "../../../shared-components";

const DeleteButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <img src="/static/img/icons/admin/delete-icon.svg" alt="delete button" />
    </Button>
  );
};

export default DeleteButton;
