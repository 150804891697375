import React from "react";

import { AddButton } from "../../shared-components";
import { DraggableMediaGrid, MediaGrid } from "../MediaGrid";
import { ModeSwitcher } from "./ModeSwitcher";
import styles from "./TabSection.module.scss";

const TabSection = ({
  medias,
  sectionTitle,
  menu = null,
  addSlide,
  handleProductRoute,
  productSlug,
  isSortingEnabled = false,
  isModeSwitcher,
  sortHandler,
  sortKey,
  selection,
  itemClick,
  isTabBlock,
}) => {
  const handleClick = (e) => {
    e.stopPropagation();
    productSlug ? handleProductRoute(productSlug) : null;
  };

  const getFirstImageUrl = () => {
    if (medias.length === 0) return null;
    if (medias.find((media) => media?.["content_type"] === "photo")) {
      return medias.find((media) => media?.["content_type"] === "photo")?.url;
    } else if (
      medias.find((media) => media.media?.["content_type"] === "photo")
    ) {
      return medias.find((media) => media.media?.["content_type"] === "photo")
        ?.media.url;
    }
    return null;
  };

  const firstPhotoSlideUrl = getFirstImageUrl();

  return (
    <div className={styles.section}>
      <div className={styles.section__header}>
        <div className={styles.section__headerLeft}>
          <div className={styles.section__ava}>
            {firstPhotoSlideUrl && (
              <img src={firstPhotoSlideUrl} alt={sectionTitle} />
            )}
          </div>
          <div>
            <div className={styles.section__title} onClick={handleClick}>
              {sectionTitle}
            </div>
            {!menu && sectionTitle && (
              <div className={styles.section__count}>{medias.length} фото</div>
            )}
            {menu && <div className={styles.section__menu}>{menu}</div>}
          </div>
        </div>

        <div className={styles.buttonsContainer}>
          {isModeSwitcher && <ModeSwitcher />}
          {!!medias.length && (
            <AddButton onClick={addSlide} className={styles.addButton} />
          )}
        </div>
      </div>

      <div className={styles.section__content}>
        {isSortingEnabled ? (
          <DraggableMediaGrid
            items={medias}
            addSlide={addSlide}
            selection={selection}
            itemClick={itemClick}
            isTabBlock={isTabBlock}
            sortHandler={sortHandler}
            sortKey={sortKey}
            id={productSlug || "12add121as"}
          />
        ) : (
          <MediaGrid
            items={medias}
            addSlide={addSlide}
            selection={selection}
            itemClick={itemClick}
            isTabBlock={isTabBlock}
          />
        )}
      </div>
    </div>
  );
};

export default TabSection;
