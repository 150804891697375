import { format } from "date-fns";
import { ru } from "date-fns/locale";
import React from "react";
import { Calendar as ReactCalendar } from "react-calendar";

import styles from "./Calendar.module.scss";

const Calendar = ({
  tilesData,
  onActiveStartDateChange,
  onClickDay,
  defaultActiveStartDate,
  value,
}) => {
  const tileContent = ({ date }) => {
    const tileDate = format(date, "yyyy-MM-dd");
    const tileInfo = tilesData?.[tileDate];
    if (tileInfo) {
      const notPublished = tileInfo?.unpublished;
      const published = tileInfo?.published;
      const withoutPhoto = tileInfo?.no_photo;

      return (
        <div className={styles.blockCountContainer}>
          {!!notPublished && <p>{notPublished}</p>}
          {!!withoutPhoto && (
            <p className={styles.blockNotFinished}>{withoutPhoto}</p>
          )}
          {!!published && <p className={styles.blockPublished}>{published}</p>}
        </div>
      );
    }
  };

  const navigationLabel = ({ date }) => {
    return format(date, "LLLL yyyy", { locale: ru });
  };

  return (
    <ReactCalendar
      defaultActiveStartDate={defaultActiveStartDate}
      value={value}
      className={styles.styledCalendar}
      view="month"
      prev2Label={null}
      next2Label={null}
      tileClassName={styles.tile}
      tileContent={tileContent}
      navigationLabel={navigationLabel}
      prevLabel={
        <img src="/static/img/icons/admin/back-icon.svg" alt="back button" />
      }
      nextLabel={
        <img src="/static/img/icons/admin/back-icon.svg" alt="back button" />
      }
      onActiveStartDateChange={onActiveStartDateChange}
      onClickDay={onClickDay}
    />
  );
};

export default Calendar;
