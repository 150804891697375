import React from "react";

import { Button } from "../../../shared-components";
import styles from "./BackButton.module.scss";

const BackButton = ({ onClick, label }) => {
  return (
    <Button onClick={onClick} className={styles.backBtnRoot}>
      <img src="/static/img/icons/admin/back-icon.svg" alt="back button" />
      <span className={styles.label}>{label}</span>
    </Button>
  );
};

export default BackButton;
