import { format, isValid } from "date-fns";
import { ru } from "date-fns/locale";
import React from "react";

import styles from "./DateField.module.scss";

const extractDate = (dateString) => {
  const fixedString = dateString.replace(/[.]/g, "-");
  return new Date(fixedString);
};

const DateField = ({ blockNumber }) => {
  const splittedNumber = blockNumber.split("-");
  const dateString = splittedNumber?.[0];
  const number = splittedNumber?.[1];

  const date = extractDate(dateString);

  const isDateValid = isValid(date);

  const formatedDate = isDateValid
    ? format(date, "dd MMM", { locale: ru })
    : format(new Date(2022, 0, 1), "dd MMM", { locale: ru });

  return (
    <div className={styles.root}>
      {formatedDate} &nbsp;/&nbsp;
      <span className={styles.number}>{number}</span>
    </div>
  );
};

export default DateField;
