import classNames from "classnames";
import React from "react";

import { Button } from "..";
import styles from "./TextButton.module.scss";

const TextButton = ({ children, onClick, disabled, className }) => {
  return (
    <Button
      onClick={onClick}
      className={classNames(styles.root, className)}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default TextButton;
