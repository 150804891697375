import React from "react";

import {
  MediaGridAdd,
  MediaGridEmpty,
  MediaGridSimpleItem,
} from "./components";
import styles from "./MediaGrid.module.scss";

const MediaGrid = ({ items, addSlide, selection, itemClick, isTabBlock }) => {
  const hasItems = Boolean(items?.length);

  return (
    <div className={styles.grid}>
      {hasItems &&
        items.map((item) => (
          <MediaGridSimpleItem
            key={item.id}
            item={item}
            itemClick={itemClick}
            selectActive={selection.selectActive}
            selectedItems={selection.selected}
            handleSelect={selection.selectHandler}
            isTabBlock={isTabBlock}
          />
        ))}
      {!hasItems && (
        <>
          <MediaGridAdd onClick={addSlide} />
          <MediaGridEmpty />
          <MediaGridEmpty />
        </>
      )}
    </div>
  );
};

export default MediaGrid;
