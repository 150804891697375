import React from "react";

import { Button } from "../../../shared-components";

const CalendarButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <img
        src="/static/img/icons/admin/calendar-icon.svg"
        alt="calendar button"
      />
    </Button>
  );
};

export default CalendarButton;
