import React from "react";

import { Button } from "../../../shared-components";

const ShareButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <img src="/static/img/icons/admin/upload-icon.svg" alt="Share button" />
    </Button>
  );
};

export default ShareButton;
