import React from "react";

const AddButton = ({ onClick, className }) => {
  return (
    <button onClick={onClick} className={className}>
      <img src="/static/img/icons/admin/plus-icon.svg" alt="add slide button" />
    </button>
  );
};

export default AddButton;
