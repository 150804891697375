import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useRecoilState } from "recoil";

import { ModalPortal } from "@/components/modals/ModalPortal";

import AdminBlocksApi from "../../../../api/admin/AdminBlocksApi";
import { useSelection } from "../../../../hooks/admin";
import { useLockBodyScroll } from "../../../../hooks/useLockBodyScroll";
import mediaPickerPayloadState from "../../blocks/TabMedia/mediaPickerPayloadState";
import TabMedia from "../../blocks/TabMedia/TabMedia";
import { Footer, Header } from "../../layout/components";
import { TextButton } from "../../shared-components";
import styles from "./AddMediaPopup.module.scss";

const AddMediaPopup = ({ closePopup, queryKey }) => {
  useLockBodyScroll();

  const queryClient = useQueryClient();

  const selection = useSelection();
  const [mediaPickerPayload, setMediaPickerPayload] = useRecoilState(
    mediaPickerPayloadState
  );

  const addProductSlideMutation = useMutation(
    ({ block_id, product_id, media_id, type = "main" }) => {
      return AdminBlocksApi.product.addSlide({
        block_id,
        product_id,
        media_id,
        type,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
      onSettled: () => {
        closePopup();
        setMediaPickerPayload({});
      },
    }
  );

  const addBlockSlideMutation = useMutation(
    ({ blockId, type = "main", mediaId }) => {
      return AdminBlocksApi.slide.add({ blockId, type, mediaId });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
      onSettled: () => {
        closePopup();
        setMediaPickerPayload({});
      },
    }
  );

  const addSlideHandler = (slidesArray) => {
    const { block_id, product_id, type, destination } = mediaPickerPayload;

    switch (destination) {
      case "product":
        slidesArray.forEach((element) => {
          addProductSlideMutation.mutate({
            block_id,
            product_id,
            media_id: element.id,
          });
        });
        return;

      case "block":
        slidesArray.forEach((element) => {
          addBlockSlideMutation.mutate({
            blockId: block_id,
            type,
            mediaId: element.id,
          });
        });
        return;

      default:
        return null;
    }
  };

  const addHandler = () => {
    addSlideHandler(selection.selected);
  };

  return (
    <ModalPortal>
      <div className={styles.popupContainer}>
        <Header
          backBtnHandler={closePopup}
          chooseHandler={selection.chooseHandler}
          className={styles.popupHeader}
        />
        <TabMedia
          selection={selection}
          isPopup
          addSlideHandler={addSlideHandler}
          className={styles.popupContent}
        />
        {selection.selectActive && (
          <Footer
            center={<TextButton onClick={addHandler}>Добавить</TextButton>}
          />
        )}
      </div>
    </ModalPortal>
  );
};

export default AddMediaPopup;
