import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { useRef, useState } from "react";

import { ModalPortal } from "@/components/modals/ModalPortal";

import { Calendar, SpinnerFrame } from "../../shared-components";
import styles from "./CalendarModal.module.scss";

function CalendarModal({ closeCalendar, setSelectedDate, selectedDate }) {
  const [dateRange, setDateRange] = useState(
    getCalendarRange(selectedDate ? new Date(selectedDate) : new Date())
  );

  const containerRef = useRef(null);

  const calendarQuery = useQuery(
    ["calendar", dateRange],
    ({ queryKey }) => {
      const date_from = queryKey?.[1]?.date_from;
      const date_to = queryKey?.[1]?.date_to;

      return axios.post("admin/blocks/calendar", {
        date_from,
        date_to,
      });
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );

  const onActiveStartDateChange = ({ activeStartDate }) => {
    setDateRange(getCalendarRange(activeStartDate));
  };

  const onClickDay = (value) => {
    setSelectedDate(format(value, "yyyy-MM-dd"));
    closeCalendar();
  };

  const closeModalHandler = (event) => {
    const target = event.target;
    if (containerRef.current === target) closeCalendar();
  };

  return (
    <ModalPortal>
      <div
        className={styles.container}
        ref={containerRef}
        onClick={closeModalHandler}
      >
        <SpinnerFrame isLoading={calendarQuery?.isLoading} />
        {dateRange && (
          <Calendar
            defaultActiveStartDate={
              selectedDate ? new Date(selectedDate) : new Date()
            }
            value={new Date(selectedDate)}
            tilesData={calendarQuery?.data?.items}
            onActiveStartDateChange={onActiveStartDateChange}
            onClickDay={onClickDay}
          />
        )}
      </div>
    </ModalPortal>
  );
}

const getCalendarRange = (date) => {
  const currentDate = new Date(date);
  const date_from = format(startOfMonth(currentDate), "yyyy-MM-dd");
  const date_to = format(endOfMonth(currentDate), "yyyy-MM-dd");

  return { date_from, date_to };
};

export default CalendarModal;
