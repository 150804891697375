import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";

import { SelectionIcon } from "../../../../shared-components";
import styles from "./MediaGridItem.module.scss";

const MediaGridItem = ({
  item,
  itemClick,
  selectActive,
  selectedItems,
  handleSelect,
  isTabBlock,
  sortKey,
}) => {
  const { id, type, url, min, poster_min, content_type } = item;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
      data: { sort: item[sortKey] },
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const onClick = () => {
    if (selectActive) {
      handleSelect(item);
    } else {
      itemClick(item);
    }
  };

  const selectedIndex = selectedItems?.findIndex((item) => item.id === id);
  const selected = selectedIndex >= 0;
  const selectedNumber = selected ? selectedIndex + 1 : null;

  const contentType = content_type ? content_type : type;

  const isMood = !!item?.is_mood;
  const hasShoptags = !!item?.shoptags?.length;

  return (
    <div
      className={styles.item}
      onClick={onClick}
      data-id={id}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className={styles.item__content}>
        {contentType === "photo" && <img src={min || url} alt="" />}
        {contentType === "video" && (
          <video
            muted={true}
            autoPlay={true}
            loop={true}
            playsInline={true}
            preload="auto"
            poster={poster_min}
          >
            <source
              src={min || url}
              type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
            />
          </video>
        )}
      </div>
      {contentType === "video" && (
        <img
          className={styles.playIcon}
          src="/static/img/icons/admin/play-icon.svg"
          alt=""
        />
      )}
      <SelectionIcon
        selected={selected}
        number={selectedNumber}
        active={selectActive}
      />
      {isTabBlock && !isMood && hasShoptags && (
        <img
          className={styles.statusIcon}
          src="/static/img/icons/admin/bag.svg"
          alt="has shoptags"
        />
      )}
      {isTabBlock && isMood && (
        <img
          className={styles.statusIcon}
          src="/static/img/icons/admin/mood.svg"
          alt="is mood"
        />
      )}
    </div>
  );
};

export default MediaGridItem;
