import classNames from "classnames";
import React from "react";

import styles from "./SelectionIcon.module.scss";

const SelectionIcon = ({ selected, active, number = null }) => {
  return (
    <div
      className={classNames(styles.selectionIcon, {
        [styles.active]: active,
        [styles.selected]: selected,
      })}
    >
      <span>{number}</span>
    </div>
  );
};

export default SelectionIcon;
