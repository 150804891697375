import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { useRouter } from "next/router";
import React from "react";

import AdminBlocksApi from "../../../../../api/admin/AdminBlocksApi";
import { Button } from "../../../shared-components";
import styles from "./ModeSwitcher.module.scss";

const ModeSwitcher = () => {
  const router = useRouter();
  const blockId = router.query.id;

  const blockQuery = useQuery(
    ["block", blockId],
    () => AdminBlocksApi.find(blockId),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  const block = blockQuery.data.data.block;

  const isAlwaysOpened = block?.always_opened;

  const modeSwitchMutation = useMutation(
    ({ alwaysOpened }) => AdminBlocksApi.edit(blockId, alwaysOpened),
    {
      onSuccess: (data) => {
        if (data.status === "success") {
          blockQuery.refetch();
        }
      },
    }
  );

  const switchToCarousel = () =>
    modeSwitchMutation.mutate({ alwaysOpened: true });

  const switchToBlock = () =>
    modeSwitchMutation.mutate({ alwaysOpened: false });

  return (
    <div className={styles.container}>
      <Button
        onClick={switchToBlock}
        className={classNames({
          [styles.active]: !isAlwaysOpened,
        })}
      >
        <img
          src="/static/img/icons/admin/block-mode-icon.svg"
          alt="block mode"
        />
      </Button>
      <Button
        onClick={switchToCarousel}
        className={classNames({
          [styles.active]: isAlwaysOpened,
        })}
      >
        <img
          src="/static/img/icons/admin/carousel-mode-icon.svg"
          alt="carousel mode"
        />
      </Button>
    </div>
  );
};

export default ModeSwitcher;
