import React from "react";

import { Button } from "../../../shared-components";
import styles from "./ThreeDotsButton.module.scss";

const ThreeDotsButton = ({ onClick }) => {
  return (
    <Button onClick={onClick} className={styles.root}>
      <img
        src="/static/img/icons/admin/three-dots-icon.svg"
        alt="menu button"
      />
    </Button>
  );
};

export default ThreeDotsButton;
