import classNames from "classnames";
import React from "react";

import styles from "./Button.module.scss";

const Button = ({
  children,
  className,
  onClick,
  type = "button",
  disabled,
}) => {
  return (
    <button
      className={classNames(styles.root, className)}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
