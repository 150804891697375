import classNames from "classnames";
import React from "react";

import styles from "./MediaGridEmpty.module.scss";

const MediaGridEmpty = () => (
  <div className={classNames(styles.item, styles.itemEmpty)} />
);

export default MediaGridEmpty;
