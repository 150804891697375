import React from "react";

import styles from "./Footer.module.scss";

const Footer = ({ leftSide, center, rightSide }) => {
  return (
    <footer className={styles.root}>
      <div>{leftSide}</div>
      <div className={styles.centerElement}>{center}</div>
      <div>{rightSide}</div>
    </footer>
  );
};

export default Footer;
