import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import AdminBlocksApi from "../../../../api/admin/AdminBlocksApi";
import { SpinnerFrame } from "../../shared-components";
import { MediaGridAdd, MediaGridEmpty, MediaGridItem } from "./components";
import styles from "./MediaGrid.module.scss";

const DraggableMediaGrid = ({
  items,
  addSlide,
  selection,
  itemClick,
  isTabBlock,
  sortHandler,
  sortKey,
  id,
}) => {
  const [sortableItems, setSortableItems] = useState(items);

  useEffect(() => {
    setSortableItems((state) => {
      if (state.length !== items.length) {
        return items;
      }
      return state;
    });
  }, [items]);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: { delay: 200, tolerance: 5 },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: { delay: 200, tolerance: 5 },
  });

  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates,
  });

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const router = useRouter();
  const blockId = router.query.id;

  const blockQuery = useQuery(
    ["block", blockId],
    () => AdminBlocksApi.find(blockId),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setSortableItems((state) => {
        const oldIndex = state.findIndex((elem) => elem.id === active.id);
        const newIndex = state.findIndex((elem) => elem.id === over.id);

        return arrayMove(state, oldIndex, newIndex);
      });

      const currentSlideId = active.id;
      const overSlideSort = over.data.current.sort;

      sortHandler({
        slideId: currentSlideId,
        sort: overSlideSort,
      });
    }
  };

  const hasItems = Boolean(items?.length);
  const isLoading = blockQuery.isLoading || blockQuery.isFetching;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <div className={styles.grid}>
        <SpinnerFrame isLoading={isLoading} />
        <SortableContext
          items={sortableItems}
          strategy={rectSortingStrategy}
          id={id}
        >
          {sortableItems.map((item) => (
            <MediaGridItem
              key={item.id}
              item={item}
              itemClick={itemClick}
              selectActive={selection.selectActive}
              selectedItems={selection.selected}
              handleSelect={selection.selectHandler}
              isTabBlock={isTabBlock}
              sortKey={sortKey}
            />
          ))}
        </SortableContext>

        {!hasItems && (
          <>
            <MediaGridAdd onClick={addSlide} />
            <MediaGridEmpty />
            <MediaGridEmpty />
          </>
        )}
      </div>
    </DndContext>
  );
};

export default DraggableMediaGrid;
