import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { useRouter } from "next/router";
import React from "react";

import AdminBlocksApi from "../../../../api/admin/AdminBlocksApi";
import { SpinnerFrame } from "../../shared-components";
import TabSection from "../TabSection/TabSection";
import { ProductsMediaSection } from "./ProductMediaSection";
import styles from "./TabMedia.module.scss";

const TabMedia = ({ selection, isPopup, addSlideHandler, className }) => {
  const router = useRouter();
  const blockId = router.query.id;

  const blockQuery = useQuery(
    ["block", blockId],
    () => AdminBlocksApi.find(blockId),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  const block = blockQuery.data.data.block;

  const itemClick = (item) => {
    if (!isPopup) return;
    addSlideHandler([item]);
  };

  /**
   * Add Media to Block
   *
   * @param {File} file
   * @param {string} source - look or product
   * @param {boolean} isMood
   * @param {string|null} productId
   * @returns {Promise<null|{media}|*>}
   */
  const addMedia = ({ files, source = "look", isMood, productId = null }) => {
    return AdminBlocksApi.media.add({
      blockId,
      files,
      isMood,
      source,
      productId,
    });
  };

  const addMediaMutation = useMutation(addMedia, {
    onSuccess: (data) => {
      if (data?.status === "success") blockQuery.refetch();
    },
    onSettled: () => {
      const input = document.getElementById("upload-input");

      if (input) {
        document.body.removeChild(input);
      }
    },
  });

  const handleUpload =
    ({ isMood = false, source = "look", productId }) =>
    () => {
      const existedInput = document.getElementById("upload-input");
      const input = existedInput
        ? existedInput
        : document.createElement("input");
      input.type = "file";
      input.id = "upload-input";
      input.multiple = true;
      input.classList.add("visually-hidden");

      document.body.appendChild(input);

      input.onchange = (e) => {
        const { files } = e.target;
        if (!files?.length) return;

        addMediaMutation.mutate({
          files,
          source,
          isMood,
          productId,
        });
      };

      input.click();
    };

  const medias = block?.medias;
  const products = block?.products;

  const mediasMain = medias.filter(
    (media) => media.source === "look" && !media.is_mood
  );
  const mediasMoods = medias.filter(
    (media) => media.source === "look" && media.is_mood
  );

  return (
    <div className={classNames(styles.container, className)}>
      <SpinnerFrame isLoading={addMediaMutation.isLoading} />
      <TabSection
        medias={mediasMain}
        sectionTitle="Образ"
        selection={selection}
        addSlide={handleUpload({})}
        itemClick={itemClick}
      />

      {!!products?.length && (
        <ProductsMediaSection
          products={products}
          medias={medias}
          handleUpload={handleUpload}
          selection={selection}
          itemClick={itemClick}
        />
      )}

      <TabSection
        medias={mediasMoods}
        sectionTitle="Mood"
        selection={selection}
        addSlide={handleUpload({ isMood: true })}
        itemClick={itemClick}
      />
    </div>
  );
};

export default TabMedia;
