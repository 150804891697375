import React from "react";

import { Button } from "../../../shared-components";
import styles from "./ChooseButton.module.scss";

const ChooseButton = ({ onClick }) => {
  return (
    <Button onClick={onClick} className={styles.root}>
      Выбрать
    </Button>
  );
};

export default ChooseButton;
