import axios from "axios";
import { parseCookies } from "nookies";

import { API_URL } from "@/config";

export const COOKIES_OPTIONS = {
  maxAge: 30 * 24 * 60 * 60,
  path: "/",
};

//@TODO: handle errors like in sendRequest function
export const sendFile = async (
  requestUrl,
  file,
  fileParamName,
  otherParams = {},
  apiUrl = API_URL
) => {
  // create form data
  const data = new FormData();

  // bind file
  data.append(fileParamName, file);

  // bind other params
  if (Object.keys(otherParams).length)
    Object.keys(otherParams).forEach((paramName) => {
      data.append(paramName, otherParams[paramName]);
    });

  const { client_id, token } = parseCookies(null);
  data.append("client_id", client_id);
  data.append("token", token);

  // send
  try {
    const response = await axios.post(apiUrl + requestUrl, data);
    if (!response.data?.status || response.data.status !== "success") {
      console.log("Error!", response.data);
      return null;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

//@TODO: handle errors like in sendRequest function
export const sendFileMultiple = async (
  requestUrl,
  files,
  fileParamName,
  otherParams = {}
) => {
  // create form data
  const data = new FormData();

  // bind file
  [...files].forEach((file, index) => {
    data.append(`${fileParamName}[${index}]`, file);
  });

  // bind other params
  if (Object.keys(otherParams).length)
    Object.keys(otherParams).forEach((paramName) => {
      data.append(paramName, otherParams[paramName]);
    });

  const { client_id, token } = parseCookies(null);
  data.append("client_id", client_id);
  data.append("token", token);

  // send
  try {
    const response = await axios.post(API_URL + requestUrl, data);
    if (!response.data?.status || response.data.status !== "success") {
      console.log("Error!", response.data);
      return null;
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
