import React, { useRef } from "react";

import { useToggle } from "../../../../../hooks/useToggle";
import { ThreeDotsButton } from "..";
import { ThreeDotOptions } from "../ThreeDotOptions";
import styles from "./ThreeDotMenu.module.scss";

const ThreeDotMenu = ({ options }) => {
  const { isOpen, close, toggle } = useToggle();
  const menuRef = useRef(null);

  return (
    <div className={styles.menuContainer} ref={menuRef}>
      <ThreeDotsButton onClick={toggle} />
      {isOpen && (
        <ThreeDotOptions close={close} options={options} menuRef={menuRef} />
      )}
    </div>
  );
};

export default ThreeDotMenu;
