import axios from "axios";

import { sendFileMultiple } from "../../helpers/client-server";

const AdminBlocksApi = {
  find: (id, credentials = null) => axios.post(`admin/blocks/${id}`),

  create: (lookId) =>
    axios.post(`admin/blocks/add`, { source_look_id: lookId }),

  publishBlock: (blockId) => axios.post(`admin/blocks/${blockId}/publish`),

  unPublishBlock: (blockId) => axios.post(`admin/blocks/${blockId}/unpublish`),

  unPublishBlockBatch: (block_ids) =>
    axios.post(`admin/blocks/batch-unpublish`, block_ids),

  edit: (blockId, alwaysOpened) =>
    axios.post(`admin/blocks/${blockId}/edit`, {
      always_opened: alwaysOpened,
    }),

  media: {
    add: ({
      blockId,
      files,
      isMood = false,
      source = "look",
      productId = null,
    }) => {
      const data = {
        is_mood: Number(isMood),
        source,
      };
      if (productId) data.source_product_id = productId;
      return sendFileMultiple(
        `admin/blocks/${blockId}/media/add`,
        files,
        "files",
        data
      );
    },

    delete: ({ block_id, id }) =>
      axios.post(`admin/blocks/${block_id}/media/${id}/delete`),
    batchDelete: ({ block_id, media_ids }) =>
      axios.post(`admin/blocks/${block_id}/media/batch-delete`, { media_ids }),
  },

  slide: {
    /**
     * Add slide to Block
     *
     * @param {string} blockId - ID of the block where we adding a slide
     * @param {"main"|"additional"} type
     * @param {string} mediaId - the media item ID
     * @returns {Promise<{message, status: string}|*|{message: *, status: string}|undefined>}
     */
    add: ({ blockId, type, mediaId }) =>
      axios.post(`admin/blocks/${blockId}/slides/add`, {
        media_id: mediaId,
        type,
      }),
    batchAdd: ({ block_id, slides, shoptags }) =>
      axios.post(`admin/blocks/${block_id}/slides/shoptags/batch-add`, {
        slides,
        shoptags,
      }),

    delete: ({ block_id, id }) =>
      axios.post(`admin/blocks/${block_id}/slides/${id}/delete`),
    batchDelete: ({ block_id, slide_ids }) =>
      axios.post(`admin/blocks/${block_id}/slides/batch-delete`, {
        slide_ids,
      }),

    edit: ({ block_id, id, type = null, sort1 = null, sort2 = null }) => {
      const dataToSend = {};
      if (type) dataToSend.type = type;
      if (sort1) dataToSend.sort1 = sort1;
      if (sort2) dataToSend.sort2 = sort2;
      return axios.post(
        `admin/blocks/${block_id}/slides/${id}/edit`,
        dataToSend
      );
    },

    addShoptag: ({
      product_id,
      block_id,
      slide_id,
      is_main = true,
      coordinates,
    }) => {
      const data = {
        colored_model_id: product_id,
        offset_x: coordinates?.x,
        offset_y: coordinates?.y,
        is_main: is_main,
      };
      return axios.post(
        `admin/blocks/${block_id}/slides/${slide_id}/shoptags/add`,
        data
      );
    },

    updateShoptag: ({
      block_id,
      slide_id,
      id,
      colored_model_id,
      offset_x,
      offset_y,
    }) => {
      const dataToSend = {
        colored_model_id,
        offset_x,
        offset_y,
      };
      return axios.post(
        `admin/blocks/${block_id}/slides/${slide_id}/shoptags/${id}/edit`,
        dataToSend
      );
    },

    removeShoptag: ({ block_id, slide_id, id }) =>
      axios.post(
        `admin/blocks/${block_id}/slides/${slide_id}/shoptags/${id}/delete`
      ),
  },

  product: {
    addSlide: ({ block_id, product_id, media_id, type }) => {
      const data = {
        media_id,
        type,
      };
      return axios.post(
        `admin/blocks/${block_id}/products/${product_id}/slides/add`,
        data
      );
    },

    editSlide: ({ block_id, product_id, slide_id, type, sort }) => {
      const dataToSend = {
        type,
        sort,
      };
      return axios.post(
        `admin/blocks/${block_id}/products/${product_id}/slides/${slide_id}/edit`,
        dataToSend
      );
    },

    delete: ({ block_id, product_id, id }) =>
      axios.post(
        `admin/blocks/${block_id}/products/${product_id}/slides/${id}/delete`
      ),
    batchDelete: ({ block_id, product_id, slide_ids }) =>
      axios.post(
        `admin/blocks/${block_id}/products/${product_id}/slides/batch-delete`,
        { slide_ids }
      ),
  },
};

export default AdminBlocksApi;
