import { useState } from "react";

import { AsfProduct } from "@/api/generatedTypes";

type SelectedItem = AsfProduct;

export const useSelection = () => {
  const [selectActive, setSelectActive] = useState(false);
  const [selected, setSelected] = useState<SelectedItem[]>([]);

  const chooseHandler = () => {
    if (selectActive) {
      setSelected([]);
      setSelectActive(false);
    } else {
      setSelectActive(true);
    }
  };

  const selectHandler = (element: SelectedItem) => {
    if (selected.some((item) => item.id === element.id)) {
      setSelected((state) => state.filter((item) => item.id !== element.id));
    } else {
      setSelected((state) => [...state, element]);
    }
  };

  const resetSelection = () => {
    setSelectActive(false);
    setSelected([]);
  };

  return {
    selectActive,
    selected,
    chooseHandler,
    selectHandler,
    resetSelection,
  };
};
