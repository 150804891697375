import classNames from "classnames";
import React from "react";

import gridItemStyles from "../MediaGridItem/MediaGridItem.module.scss";
import styles from "./MediaGridAdd.module.scss";

const MediaGridAdd = ({ onClick }) => {
  return (
    <button
      className={classNames(gridItemStyles.item, styles.itemAdd)}
      onClick={onClick}
    >
      <div
        className={classNames(
          gridItemStyles.item__content,
          styles.itemAdd__content
        )}
      >
        <img
          className={styles.icon}
          src="/static/img/icons/admin/plus-icon.svg"
          alt="add slide button"
        />
      </div>
    </button>
  );
};

export default MediaGridAdd;
