import React, { useState } from "react";

import { TabSection } from "../../TabSection";
import { ProductChoose } from "../ProductChoose";

const ProductsMediaSection = ({
  products,
  medias,
  selection,
  itemClick,
  handleUpload,
}) => {
  const [activeProductTab, setActiveProductTab] = useState(products[0].id);

  const mediasProducts = medias.filter(
    (media) =>
      media.source === "product" && media.source_product_id === activeProductTab
  );

  return (
    <TabSection
      medias={mediasProducts}
      sectionTitle="Товары"
      menu={
        <ProductChoose
          products={products}
          activeProductTab={activeProductTab}
          setActiveProductTab={setActiveProductTab}
        />
      }
      selection={selection}
      addSlide={handleUpload({
        source: "product",
        productId: activeProductTab,
      })}
      itemClick={itemClick}
    />
  );
};

export default ProductsMediaSection;
