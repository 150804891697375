import classNames from "classnames";

import { Spinner } from "../Spinner";
import styles from "./SpinnerFrame.module.scss";

const SpinnerFrame = ({ isLoading, fullScreen = false }) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.hidden]: !isLoading,
        [styles.fullscreen]: fullScreen,
      })}
    >
      <div className={styles.bluredBackground} />
      <Spinner />
    </div>
  );
};

export default SpinnerFrame;
