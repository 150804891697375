import { useClickOutside } from "../../../../../hooks/useClickOutside";
import styles from "./ThreeDotOptions.module.scss";

const ThreeDotOptions = ({ close, options = [], menuRef }) => {
  useClickOutside(menuRef, close);

  const itemClick = (itemFunc) => () => {
    itemFunc();
    close();
  };

  if (!options.length) return null;

  return (
    <div className={styles.optionsContainer}>
      {options.map((item) => (
        <button
          className={styles.optionsItem}
          key={item.id}
          onClick={itemClick(item.itemFunc)}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

export default ThreeDotOptions;
