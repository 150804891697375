import classNames from "classnames";
import React from "react";

import { BackButton, ChooseButton, ThreeDotMenu } from "..";
import styles from "./Header.module.scss";

const Header = ({
  backBtnHandler,
  chooseHandler,
  menuOptions,
  backBtnLabel,
  className,
}) => {
  return (
    <header className={classNames(styles.root, className)}>
      <BackButton label={backBtnLabel} onClick={backBtnHandler} />
      <img className={styles.logo} src="/static/img/icons/admin/logo.svg" />
      <div className={styles.rightSide}>
        <ChooseButton onClick={chooseHandler} />
        <ThreeDotMenu options={menuOptions} />
      </div>
    </header>
  );
};

export default Header;
